<template>
  <v-container>
    <page-title title="編輯營業時間" />
    <formPlugin :form-key="formKey" :component="formContent"></formPlugin>
    <eagle-form :form-key="formKey" >
    </eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formPlugin from "@/components/eaglePlugin/formPlugin.vue"
import formConfig from './providerTimeEdit'

export default {
  mixins: [formMixin],
  components: {
    formPlugin
  },
  
  data: () => ({
    formKey: 'provider-business-hours-form',
    meta: {},
  }),

  computed: {
    formContent() {
      return () => import('@/modules/provider/views/timeCard/timePivot.vue')
    },
    isMainManager() {
      return this.$store.getters['member/isProviderMainManager']
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      const res =  await this.$api.collection.providerApi.getTimes(this.providerId)
      return res
    },
    async updateApi(target, formData) {


       await this.$api.collection.providerApi.setTimes(this.providerId, { business : formData })

    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
