<template>
  <component :is="component" v-model="data" />
</template>

<script>
export default {
  props: {
    formKey: String,
    component: Function,
  },
  data: () => ({
    data: null,
  }),
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`];
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.formData);
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.formData, this.data)) return;
        this.sync();
      },
    },
  },
  methods: {
    async sync() {
      await this.$nextTick();
       this.$store.dispatch(`form/${this.formKey}/setData`, this.data);
    },
  },
};
</script>